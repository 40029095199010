<template>
  <div class="betting-area">
    <Schedule :offer="offer"
              :roundLabel="roundLabel"
              ref="schedule"
              @selectedRound="selectRound">
    </Schedule>
    <component :is="bettingComponent"
               :tabs="bettingComponentsTabs"
               :raceCardRows="racers"
               :raceCardColumns="raceCardColumns"
               :tricastColumns="tricastColumns"
               :activeButtons="tickets"
               :activeReverseBets="revTickets"
               direction="vertical"
               :colors="labelColors"
               :racers="racers"
               :raceCardHeaderItems="raceCardHeaderItems"
               :raceCardOdds="raceCardOdds"
               :forecastOdds="forecastOdds"
               :reverseForecastOdds="reverseForecastOdds"
               :headToHeadOdds="headToHeadOdds"
               :bettingDisabled="bettingDisabled"
               :activeRound="activeRound"
               @tab-changed="setDrawDetailsHeight"
               @selectRaceCard="selectRacecardOdd"
               @selectForecast="selectForecastOdd"
               @selectH2H="selectH2HOdd"
               @selectTricastOdd="selectTricastOdd">
    </component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  map,
  find,
  join,
  each,
} from 'lodash';
import { Schedule, eventBus } from '@nsftx/games-sdk-js';
import store from '@/store';
import RacesBettingAreaA from './RacesBettingAreaA';
import HeadToHeadGrid from './HeadToHeadGrid';
import RaceCardGrid from './RaceCardGrid';
import ForecastGrid from './ForecastGrid';
import TricastGrid from './TricastGrid';

export default {
  name: 'BettingArea',
  components: {
    Schedule,
    RacesBettingAreaA,
  },
  data() {
    return {
      raceCardColumns: 3,
      tricastColumns: 4,
      raceCardHeaderItems: '',
      activeRound: null,
      eventId: null,
      bettingComponentsTabs: [],
    };
  },
  computed: {
    ...mapGetters([
      'gamesBetslip/ticket',
      'config',
      'labelColors',
      'racers',
      'forecastOdds',
      'reverseForecastOdds',
      'reverseForecastCurrentRound',
      'raceCardOdds',
      'headToHeadOdds',
      'offer',
      'disabledRound',
      'translations',
      'isPayinInProgress',
      'currentRound',
      'isDesktop',
      'isTerminal',
    ]),
    bettingComponent() {
      return this.config.areas.bettingArea;
    },
    tickets() {
      return this['gamesBetslip/ticket'];
    },
    revTickets() {
      return this.reverseTickets();
    },
    roundLabel() {
      return this.translations.general_round;
    },
    racerLabel() {
      return this.translations.general_racer;
    },
    bettingDisabled() {
      const selectedRoundExist = find(this.offer, {
        eventIdToday: this.activeRound.eventIdToday,
      });
      if (!selectedRoundExist && this.disabledRound) {
        return true;
      }
      return this.activeRound.eventIdToday === this.disabledRound;
    },
    maxNumOfBets() {
      return this.config.rules.maxBetNumber.value;
    },
    components() {
      const tricastConfig = find(this.config.bets, {
        id: 11,
      });
      const defaultBets = [
        {
          id: 0,
          key: 0,
          name: 'RaceCardGrid',
          label: this.translations.greyhound_race_card,
          component: RaceCardGrid,
          disabled: false,
        },
        {
          id: 1,
          key: 1,
          name: 'ForecastGrid',
          label: this.translations.greyhound_forecast,
          component: ForecastGrid,
          disabled: false,
        },
      ];
      const tricast = {
        id: 2,
        key: 2,
        name: 'TricastGrid',
        label: this.translations.greyhound_tricast,
        component: TricastGrid,
        disabled: false,
      };
      if (tricastConfig && tricastConfig.value) {
        defaultBets.push(tricast);
      }
      return defaultBets;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', [
      'addBet',
      'removeBet',
    ]),
    ...mapActions([
      'parseOdds',
      'setHeadToHeadCombs',
      'setCurrentRound',
      'stopBetting',
      'betslip/setTicketPayinLoader',
    ]),
    setDrawDetailsHeight() {
      if (this.isDesktop && !this.isTerminal) {
        const bettingElement = document.getElementsByClassName('betting')[0];
        const eventsAreaElement = document.getElementsByClassName('events-area')[0];
        setTimeout(() => {
          eventsAreaElement.style.height = `${bettingElement.getBoundingClientRect().height}px`;
        }, 0);
      }
    },
    reverseTickets() {
      const currentBets = [];
      each(this.tickets, (ticketBet) => {
        if (ticketBet.betTypeId === 12) {
          currentBets.push(ticketBet);
        }
      });
      const currentBetsReverse = currentBets.slice(0);
      const additionalBets = [];
      each(currentBetsReverse, (bet) => {
        each(this.reverseForecastOdds, (row) => {
          each(row, (rowBet) => {
            if (rowBet.reverseDisplayId === bet.displayId) {
              rowBet.round = bet.round; // eslint-disable-line
              additionalBets.push(rowBet);
            }
          });
        });
      });
      const combinedBets = currentBetsReverse.concat(additionalBets);
      this.parseOdds(this.activeRound.odds);
      if (this.activeRound.eventIdToday < this.offer[0].eventIdToday
        && this.offer[0].eventIdToday === 1) {
        this.selectRound(this.offer[0].eventIdToday);
      }
      return combinedBets;
    },
    setTabs() {
      map(this.tabs, (t) => {
        const tab = t;
        tab.label = this.translations[tab.label];
      });
    },
    setRaceCardHeaderLabel() {
      map(this.raceCardHeaderItems, (headerItem) => {
        const item = headerItem;
        item.title = this.translations[item.title];
      });
    },
    selectForecastOdd(event) {
      if (event.reverse) {
        this.parseReverseForecastBet(event);
      } else {
        this.parseForecastBet(event);
      }
    },
    selectRacecardOdd(event) {
      this.parseRacecardBet(event);
    },
    selectH2HOdd(event) {
      this.parseH2HBet(event);
    },
    selectTricastOdd(data) {
      this.parseTricastBet(data);
    },
    parseTricastBet(data) {
      const bet = data;
      if (this.isTerminal) {
        bet.event = data.roundNumber;
        bet.displayValue = data.outcome;
        bet.displayName = data.market;
        bet.input = data.racers;
        bet.numOfComb = data.combinations;
      }
      this.addBet(data);
    },
    parseForecastBet(data) {
      const bet = data;
      bet.odds = data.value;
      bet.market = data.outcome;
      bet.eventId = this.eventId;
      bet.betTypeId = 10;
      bet.racers = [[data.racers[0]], [data.racers[1]]];
      bet.outcome = `${join(data.racers, '-')}`;
      bet.round = this.activeRound.eventIdToday;
      bet.roundNumber = this.activeRound.eventIdToday;
      const forecastBetOnTicket = find(this.tickets, bet);
      if (this.isTerminal) {
        bet.event = this.activeRound.eventIdToday;
        bet.displayValue = data.outcome;
        bet.displayName = data.market;
        bet.input = data.racers;
        bet.numOfComb = 1;
      }

      if (forecastBetOnTicket) {
        this.isBetslipFull();
        this.removeBet(forecastBetOnTicket.id);
        return;
      }
      this['betslip/setTicketPayinLoader'](false);
      this.addBet(bet);
    },
    parseReverseForecastBet(data) {
      const bet = data;
      const firstRacer = data.racers[0];
      const secondRacer = data.racers[1];
      bet.combinations = 2;
      bet.eventId = this.eventId;
      bet.market = `${this.translations.greyhound_reverse} ${this.translations.greyhound_forecast} (${this.translations.general_combinations}: ${bet.combinations})`;
      bet.racers = firstRacer < secondRacer
        ? [firstRacer, secondRacer] : [secondRacer, firstRacer];
      bet.betTypeId = 12;
      bet.maxOdds = data.value > data.reverseOddValue ? data.value : data.reverseOddValue;
      bet.odds = null;
      bet.outcome = firstRacer < secondRacer ? `${firstRacer}-${secondRacer}` : `${secondRacer}-${firstRacer}`;
      if (this.isTerminal) {
        bet.event = this.activeRound.eventIdToday;
        bet.displayValue = data.outcome;
        bet.displayName = bet.market;
        bet.input = data.racers;
        bet.numOfComb = 2;
      }
      const reversebetOnTicket = find(this.revTickets,
        {
          reverse: true,
          racers: data.racers,
          round: data.round,
          betTypeId: 12,
        });
      if (reversebetOnTicket) {
        this.isBetslipFull();
        this.removeBet(reversebetOnTicket.id);
        return;
      }
      this['betslip/setTicketPayinLoader'](false);
      this.addBet(bet);
    },
    parseRacecardBet(data) {
      const bet = data;
      bet.stake = 0;
      bet.round = this.activeRound.eventIdToday;
      bet.roundNumber = this.activeRound.eventIdToday;
      bet.eventId = this.eventId;
      bet.market = data.outcome;
      if (bet.betTypeId === 0) {
        bet.outcome = `${this.racerLabel} ${bet.racerMarket}`;
      }
      if (bet.betTypeId !== 0) {
        bet.racers = data.racers[0] || data.racers;
        if (bet.racerMarket === 'undefined') {
          bet.racerMarket = data.racers;
          bet.outcome = `${this.racerLabel} ${bet.racers}`;
        } else {
          bet.outcome = `${this.racerLabel} ${bet.racers}`;
        }
      }
      if (this.isTerminal) {
        bet.event = data.round;
        bet.displayValue = data.racerMarket;
        bet.displayName = data.market;
        bet.input = [data.racerMarket];
        bet.numOfComb = 1;
      }
      //  bet.odd = data.value;
      bet.odds = data.value;
      const raceCardbetOnTicket = find(this['gamesBetslip/ticket'],
        {
          racers: bet.racers,
          round: bet.round,
          market: bet.market,
        });
      if (raceCardbetOnTicket) {
        this.isBetslipFull();
        this.removeBet(raceCardbetOnTicket.id);
        return;
      }
      this['betslip/setTicketPayinLoader'](false);
      this.addBet(bet);
    },
    parseH2HBet(data) {
      const bet = data;
      const firstRacer = bet.racers[0];
      const secondRacer = bet.racers[1];
      bet.eventId = this.eventId;
      bet.market = firstRacer < secondRacer ? `${this.translations.vms_head_to_head} (${this.racerLabel} ${firstRacer} - ${this.racerLabel} ${secondRacer})`
        : `${this.translations.vms_head_to_head} (${this.racerLabel} ${secondRacer} - ${this.racerLabel} ${firstRacer})`;
      bet.roundNumber = this.activeRound.eventIdToday;
      bet.odds = data.value;
      bet.outcome = `${this.racerLabel} ${firstRacer}`;
      bet.racers = [[bet.racers[0]], [bet.racers[1]]];
      if (this.isTerminal) {
        bet.event = data.roundNumber;
        bet.displayValue = data.outcome;
        bet.displayName = data.market;
        bet.input = data.racers;
        bet.numOfComb = 1;
      }

      const opositeKey = data.uniqueKey[0] === 'L' ? 'R' : 'L';
      const betOnTicket = find(this.tickets, bet);
      const isOpositeSelected = find(this.tickets,
        {
          roundNumber: data.roundNumber,
          uniqueKey: `${opositeKey}${data.uniqueKey[1]}`,
        });

      if (isOpositeSelected) {
        this.removeBet(isOpositeSelected.id);
      }

      if (betOnTicket) {
        this.isBetslipFull();
        this.removeBet(betOnTicket.id);
        return;
      }
      this['betslip/setTicketPayinLoader'](false);
      this.addBet(bet);
    },
    selectRound(event) {
      this.activeRound = find(this.offer, { eventIdToday: event });
      this.eventId = this.activeRound.eventId;
      this.setCurrentRound(this.activeRound.eventIdToday);
    },
    parseRaceCardHeaderItems() {
      const parsedItems = [];
      const raceCardHeaderItems = [
        {
          id: 0,
          title: 'general_winner',
        },
        {
          id: 2,
          title: 'general_place',
        },
        {
          id: 1,
          title: 'general_show',
        },
      ];
      for (let i = 0; i < raceCardHeaderItems.length; i += 1) {
        Object.keys(this.config.bets).forEach((key) => {
          if (raceCardHeaderItems[i].id === this.config.bets[key].id
            && this.config.bets[key].value) {
            parsedItems.push(raceCardHeaderItems[i]);
          }
        });
      }
      this.raceCardColumns = parsedItems.length;
      this.raceCardHeaderItems = parsedItems;
    },
    isBetslipFull() {
      if (this.maxNumOfBets && this.maxNumOfBets === this.tickets.length) {
        store.dispatch('notifications/clearNotification');
      }
    },
    setHeadToHeadTab() {
      if (this.offer[0].odds.headToHead) {
        const h2h = {
          id: 2,
          key: 2,
          name: 'HeadToHeadGrid',
          label: this.translations.vms_head_to_head,
          component: HeadToHeadGrid,
          disabled: false,
        };
        this.bettingComponentsTabs.push(h2h);
      }
    },
  },
  created() {
    this.bettingComponentsTabs = this.components;
    this.setHeadToHeadTab();
    this.setTabs();
    this.parseRaceCardHeaderItems();
    this.setRaceCardHeaderLabel();
    this.selectRound(this.offer[0].eventIdToday);
    this.setHeadToHeadCombs();
  },
  mounted() {
    console.log('translations: ', this.translations);
    this.setDrawDetailsHeight();
    eventBus.$on('updateSchedule', () => {
      if (this.currentRound < this.offer[0].eventIdToday) {
        this.$refs.schedule.toggle(this.offer[0], 0);
      } else {
        this.$refs.schedule.toggle(find(this.offer,
          { eventIdToday: this.currentRound }), this.offer.indexOf(find(this.offer, {
          eventIdToday: this.currentRound,
        })));
      }
    });
    eventBus.$on('switchToNextRound', () => {
      this.$refs.schedule.toggle(find(this.offer,
        { eventIdToday: this.currentRound + 1 }), this.offer.indexOf(find(this.offer, {
        eventIdToday: this.currentRound + 1,
      })));
    });
  },
};
</script>

<style lang="scss">
  .betting-area {
    float: left;
    width: 100%;
    background: var(--card);
    color: #efefef;

    .tabs {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: unset;
      .races-buttons-grid {
        padding: 16px 8px;
        .header {
          margin-bottom: 8px;
        }
      }
      .forecast-tab-wrapper {
        padding: 16px 8px;
      }
      .tab-button {
        max-width: 180px;
        width: 100%;
        cursor: pointer;
      }
      .empty {
        flex-basis: 100%;
      }
    }
  }
  @media all and (max-width: 420px) {
    .betting-area {
      .tabs {
        .empty {
          display: none;
        }
      }
    }
  }
</style>
