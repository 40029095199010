<template>
  <div class="h2h-wrapper">
    <div class="header">
    </div>
    <div class="row-wrapper" >
      <div class="label-wrapper" :class="{ mobile: isMobile }">
        <div class="header"></div>
        <RacerLabel v-for="(row, index) in headToHeadOdds"
                    class="label"
                    :key="index"
                    :ordinalNumber="row[0].racers[0]"
                    :color="$attrs.colors[row[0].racers[0] - 1]"
                    height="40"
                    orientation="right">
        </RacerLabel>
      </div>
      <div class="info-wrapper">
        <div class="header"></div>
        <div class="info" v-for="(racer, index) in headToHeadOdds"
          :key="index">
          {{ getLabel(racer) }}
        </div>
      </div>
      <div class="label-wrapper" :class="{ mobile: isMobile }">
        <div class="header"></div>
        <RacerLabel v-for="(row, index) in headToHeadOdds"
                    class="label"
                    :key="index"
                    :ordinalNumber="row[0].racers[1]"
                    :color="$attrs.colors[row[0].racers[1] - 1]"
                    height="40"
                    orientation="right">
        </RacerLabel>
      </div>
      <div class="button-wrapper">
        <div class="header">1</div>
        <Button v-for="(row, index) in headToHeadOdds"
          :key="index"
          :disabled="$attrs.bettingDisabled || headToHeadOdds[index][0].value === 0"
          :variation="isActive(headToHeadOdds[index][0]) ? 'brand' : 'default'"
          :label="row[0].value === 0 ? '-' : row[0].value.toFixed(2)"
          @change="[selectOdd(row[0])]">
        </Button>
      </div>
      <div class="button-wrapper">
        <div class="header">2</div>
        <Button v-for="(row, index) in headToHeadOdds"
          :key="index"
          :disabled="$attrs.bettingDisabled || headToHeadOdds[index][1].value === 0"
          :variation="isActive(headToHeadOdds[index][1]) ? 'brand' : 'default'"
          :label="row[1].value === 0 ? '-' : row[1].value.toFixed(2)"
          @change="[selectOdd(row[1])]">
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import { mapGetters } from 'vuex';
import { Button } from '@nsftx/games-sdk-js';
import RacerLabel from '@/components/RacerLabel';

export default {
  name: 'HeadToHeadGrid',
  components: {
    RacerLabel,
    Button,
  },
  data() {
    return {
      odds: this.headToHeadOdds,
    };
  },
  computed: {
    ...mapGetters({
      translations: 'translations',
    }),
    ...mapGetters([
      'config',
      'headToHeadOdds',
    ]),
    isMobile() {
      return window.innerWidth < 660;
    },
  },
  methods: {
    selectOdd(odd) {
      const headToHeadOdd = {};
      headToHeadOdd.betTypeId = odd.betTypeId;
      headToHeadOdd.racers = odd.racers;
      headToHeadOdd.column = odd.column;
      headToHeadOdd.uniqueKey = odd.uniqueKey;
      headToHeadOdd.value = odd.value;
      headToHeadOdd.market = odd.racers[0].toString();
      headToHeadOdd.h2h = true;
      headToHeadOdd.outcome = 'Head to Head';
      this.$emit('change', headToHeadOdd);
    },
    isActive(odd) {
      const value = !!find(this.$attrs.activeButtons, {
        uniqueKey: odd.uniqueKey,
        roundNumber: odd.round,
      });
      return value;
    },
    getLabel(racer) {
      if (this.isMobile) {
        return `${racer[0].racers[0]} VS ${racer[0].racers[1]}`;
      }
      return `${this.translations.general_racer} ${racer[0].racers[0]} VS ${this.translations.general_racer} ${racer[0].racers[1]}`;
    },
  },
  mounted() {
    this.odds = this.headToHeadOdds;
  },
};
</script>

<style lang="scss" scoped>
.h2h-wrapper {
  background-color: var(--card);
  .header {
    color: var(--text-primary-1);
    opacity: 0.6;
    height: 14px;
    margin-bottom: 7px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }
  .row-wrapper {
    padding: 0px 8px 8px 8px;
    display: flex;
    width: 100%;
    .label-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 100px;
      width: 100%;
      &.mobile {
        max-width: 50px;
      }
      .label {
        margin: 1px;
      }
    }
    .info-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
      .info {
        font-family: 'Roboto';
        font-size: 14px;
        color: var(--text-primary-1);
        background-color: var(--card-section);
        height: 40px;
        line-height: 40px;
        margin: 1px;
        max-width: 268px;
        width: 100%;
      }
    }
    .button-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 170px;
      width: 100%;
      margin: 1px;
      button {
        margin: 1px;
      }
    }
  }
}
</style>
